import { hot } from 'react-hot-loader'
import Homepage from './Homepage'
import { LAYOUT_SECTIONS, WIDGETS } from '../constants'

class Volny extends Homepage {
  getDesktopWidgetsLayout () {
    const layout = super.getDesktopWidgetsLayout()
    layout[LAYOUT_SECTIONS.OUTSIDE_CONTENT] = [
      WIDGETS.HOMEPAGE.NATIVE_APP_PROMO
    ]

    return layout
  }

  getTabletWidgetsLayout () {
    const layout = super.getTabletWidgetsLayout()
    layout[LAYOUT_SECTIONS.OUTSIDE_CONTENT] = [
      WIDGETS.HOMEPAGE.NATIVE_APP_PROMO
    ]

    return layout
  }

  getMobileWidgetsLayout () {
    const layout = super.getMobileWidgetsLayout()
    layout[LAYOUT_SECTIONS.OUTSIDE_CONTENT] = [
      WIDGETS.HOMEPAGE.NATIVE_APP_PROMO,
      WIDGETS.COMMON.SMART_ADVERT_4
    ]

    return layout
  }
}

export default hot(module)(Volny)

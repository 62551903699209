import '@babel/polyfill'
import React from 'react'
import { render } from 'react-dom'
import Volny from '../layouts/volny'
import SentryWrapper from '../components/utils/Sentry'

const {
  sentry,
  version,
  environment
} = __INITIAL_STATE__.config
const container = document.getElementById('homepage')

render(
  <SentryWrapper config={sentry} version={version} environment={environment}>
    <Volny widgets={__INITIAL_STATE__} layout='volny' clientSide/>
  </SentryWrapper>,
  container
)
